import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";
import Avator from "assets/businessavator.webp";

function ReliableTranslation() {
  const newBg = {
    background:
      "linear-gradient(180deg, #E8F1F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  };

  return (
    <section className=" pb-8 pt-10 lg:pt-20  relative" style={newBg}>
      <div className="container mx-auto px-4  lg:pb-10 ">
        <div className="grid lg:grid-cols-2  mr-0 gap-20">
          <div>
            <h2
              className="font-opensans xs:font-secondary font-bold text-midBlue  l:pr-[260px] mb-2"
              style={{
                fontSize: "clamp(1.5rem, 3vw, 2.75rem)",
                lineHeight: "clamp(2rem, 4vw, 3.5rem)",
              }}
            >
              Business Language Solutions That Grow With You
            </h2>
            <p
              className="font-opensans  text-midBlue mb-6 text-left mt-4"
              style={{
                fontSize: "clamp(1rem, 1.4vw, 1.25rem)",
                lineHeight: "clamp(1.5rem, 3vw, 1.75rem)",
              }}
            >
              Tomedes provides solutions that scale with your business growth.
              Our hybrid approach—combining AI with human expertise—delivers
              fast, high-quality results tailored to your industry.
            </p>
          </div>
          {/* <OptimizedImage
            src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/desktop/business-translation.webp"
            alt=""
            className="w-9/12 h-full ml-20 hidden lg:block"
          /> */}
          <img src={Avator} alt="avator" />
          <div className="">
            <h3 className="font-opensans xs:font-secondary font-semibold text-midBlue text-[26px] l:pr-[260px] mb-2">
              Customized Enterprise Translation Solutions
            </h3>
            <p className="font-opensans text-lg text-midBlue mb-6 text-left mt-4">
              Tomedes provides tailored translation services for businesses
              worldwide across various industries. Our translators leverage
              advanced AI to ensure quality and consistency, while automation
              handles repetitive tasks. This allows our experts to focus on
              business objectives, adhere to industry standards, and effectively
              engage target audiences.
            </p>
          </div>
          <div>
            <h3 className="font-opensans xs:font-secondary font-semibold text-midBlue text-[26px] l:pr-[260px] mb-2">
              Industry Specialization
            </h3>
            <p className="font-opensans text-lg text-midBlue mb-6 text-left mt-">
              Our team of professional translators and experts offers accurate,
              culturally adapted translations across various industries. We have
              a proven track record of meeting the needs of businesses in
              sectors such as legal, software, healthcare, EdTech, and more. We
              are fully equipped to scale with your business requirements.
            </p>
          </div>
        </div>
        {/* <div className="lg:grid lg:grid-cols-2 lg:mx-auto lg:max-w-screen-lg mr-0">
          <div className="lg:ml-20 lg:h-[432px] relative mt-14 lg:mt-0"></div>
        </div> */}
        <div className="lg:grid lg:grid-cols-1 lg:mx-auto lg:max-w-screen-lg mr-0 mb-12 mt-20 xl:mt-24">
          <div className="bg-learnmoreBg bg-no-repeat bg-cover bg-center lg:h-[160px] px-4 py-6 lg:px-[100px] lg:py-7 flex items-center justify-center">
            <p className="text-center text-white font-opensans xs:font-secondary text-24 sm:text-[28px]">
              {/* Need Professional Translations? */}
              Get a{" "}
              <Link
                to="/quote"
                className="underline hover:text-midBlue transition-all duration-300"
              >
                free quote
              </Link>{" "}
              now.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReliableTranslation;
